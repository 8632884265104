<template>
  <div class="content-main" id="top">
    <div class="bg-main noto-sans" style="position: relative;">
      <div class="ts-banner text-center">
        <Particles id="tsparticles" :options="VoltCanversOpt" />
        <div class="ts-title mb-4">
          <div style="display: flex; align-items: center;">
            <p class="nanum-square-ExBold mr-3 mb-0" style="font-size: 5rem;">Xcost</p>
            <p class="nanum-square-bold mb-0" style="font-size: 3rem;">빠르고 정확한 적산 솔루션</p>
          </div>
          <p class="nanum-square-bold mb-0" style="font-size: 2rem;">저렴한 가격, 좋은품질, 다양한 기능이 있는 적산 솔루션을 찾고계신가요?</p>
          <p class="nanum-square-bold mb-0" style="font-size: 2rem;">Xcost에서 모든 고민을 한방에 해결해드리겠습니다.</p>
          <div class="mt-4" style="display: flex;">
            <b-button class="store-move-btn pl-4 pr-4 p-2" href="#/store">나에게 맞는 적산 솔루션 구입</b-button>
          </div>
        </div>
      </div>
  
      <section id="start" class="py-5 fc-b section-pt-5" style="margin-top: 5rem; background-color: #f4f4f4; text-align: -webkit-left;">
        <div class="pl-5" style="color: black;">
          <h1 class="mb-2 nanum-square-bold">바로 시작하기</h1>
        </div>
        <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-left ml-auto mr-auto pl-4 pr-4">
          <div class="card h-100 m-add-size" style="min-width: 22rem; min-height: 340px !important; margin: 1rem 1rem;">
            <div class="card-body p-4">
              <div class="text-center">
                <img src="../../../public/images/xcost-free.png" alt="xcost-free" style="width: 100%;">
              </div>
              <div class="mt-4 text-left">
                <h4 style="color: black;">기본기능</h4>
              </div>
  
              <div class="mt-2 text-left" style="font-size: 12pt;">
                <p style="color: dimgray;">Xcost 적산 무료버전에서 스마트한 적산 솔루션을 사용해보세요. 여러개의 프로젝트를 편리하게 관리할 수 있습니다.</p>
              </div>
  
              <div class="card-footer border-top-0 bg-transparent mt-4">
                <div class="text-center">
                  <b-button class="btn btn-outline-howcost mt-auto py-2 pl-5 pr-5" style="border-radius: 30px; font-size: large;" :href="$xcostDownload">다운로드</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="mt-4 pl-5" style="color: black;">
          <h4>더 많은 기능을 사용하고 싶으신가요?</h4>
          <b-button class="store-move-btn mt-2 pl-4 pr-4 p-2 fc-b" style="border-color: black;" href="#/store">나에게 맞는 적산 솔루션 구입</b-button>
        </div>
      </section>
  
      <section id="intro" class="py-5 fc-w" style="background-color: #27282c; text-align: -webkit-left;">
        <div class="pl-5">
          <h1 class="mb-0 nanum-square-bold">전세계 수천명의 사용자들이 Xcost를 이용하고 있습니다.</h1>
        </div>
      </section>
  
      <!-- 내역 소개 -->
      <section id="details" class="py-5 fc-b section-pt-5" style="border-top: #c2c2c2 solid 1px; background-color: #fff; text-align: -webkit-left;">
        <div class="pl-5">
          <h1 class="mb-0 nanum-square-bold">비교불허! 막강한 내역 솔루션</h1>
        </div>
        <div class="pl-5 pr-5 mt-4 " style="font-size: large; color: dimgray;">
          <p class="mb-0 fc-b">최적화된 내역 기능</p>
          <p>매월 변경되는 최신자원, 방대한 누적자원으로 최적의 내역적산을 진행 할 수 있습니다.</p>
        </div>
  
        <!-- 산출 기능 소개 -->
        <div class="px-3">
          <div class="row row-cols-xl-4 px-4 mt-4 menu-3-form">
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="book-half" class="intro-icon" />
              <h4 class="mt-2 mb-3">스펙북</h4>
              <p style="font-size: 13pt; color: dimgray;">공사자원의 이미지/ 제공업체 등 세부 정보를 추가적으로 관리할 수 있습니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="building" class="intro-icon" />
              <h4 class="mt-2 mb-3">전문견적비교</h4>
              <p style="font-size: 13pt; color: dimgray;">업체로부터 견적받은 내용을 관리할 수 있습니다. 단가조사표에 적용하여 건설 자원 가격에 반영할 수 있고, 제공업체 및 거래규모 등 정보를 저장합니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="cash-stack" class="intro-icon" />
              <h4 class="mt-2 mb-3">자원다운로드 및 관리</h4>
              <p style="font-size: 13pt; color: dimgray;">매달 내역/물량산출 통합자원자료를 다운로드 받을 수 있습니다. 사용하던 자원에 단가이동 및 관리가 가능합니다.</p>
            </div>
          </div>
        </div>
  
        <div class="pl-5 pr-5 mt-4 " style="font-size: large; color: dimgray;">
          <div class="icon-right">
            <p class="mt-3 mb-0 fc-b">설계내역</p>
            <li>공사 프로젝트를 생성하여 설계내역을 작성할 수 있습니다. 공종별로 자원(자재 및 품셈)을 이용하여 작성합니다.</li>
    
            <p class="mt-3 mb-0 fc-b">실행내역</p>
            <li>원내역에서 작성한 내역을 바탕으로 실행내역서를 작성할 수 있습니다. 수량, 단가를 요율에 따라 복사하여 작성합니다.</li>
    
            <p class="mt-3 mb-0 fc-b">견적내역</p>
            <li>원내역에서 작성한 내역을 바탕으로 견적내역서를 작성할 수 있습니다. 수량, 단가를 요율에 따라 복사하여 작성합니다.</li>
    
            <p class="mt-3 mb-0 fc-b">기성내역</p>
            <li>계약내역서에서 각 시점을 기준으로 기성내역을 작성하여 금액을 청구합니다.</li>
    
            <p class="mt-3 mb-0 fc-b">설계변경</p>
            <li>공사량의 증감이 발생한 때에는 계약내역서에서  계약수량과 금액을 조정합니다. 변경내역서를 출력할 수 있습니다.</li>
    
            <p class="mt-3 mb-0 fc-b">자원 CAD데이터 관리</p>
            <li>사용하는 자원 데이터에 CAD도형데이터를 저장하여 쉽게 관리할 수 있습니다.</li>
          </div>
        </div>
  
      </section>
  
      <!-- 구조 소개 -->
      <section id="structure" class="py-5 fc-b section-pt-5" style="border-top: #c2c2c2 solid 1px; background-color: #fff; text-align: -webkit-left;">
        <div class="pl-5">
          <h1 class="mb-0 nanum-square-bold">스마트한 구조 솔루션</h1>
        </div>
        <div class="pl-5 pr-5 mt-4 " style="font-size: large; color: dimgray;">
          <p class="mb-0 fc-b">부위에 특화된 입력 환경 제공</p>
          <p>부위마다 특화된 입력 환경을 제공하고, 자동으로 수량 산출을 할 수 있습니다.</p>
  
          <p class="mb-1 mb-2 fc-b">물량 산출 업무의 효율성을 높여주는 다양한 기능들</p>
          <p class="mt-2 mb-0">물량연계는 내역서에 연계하는 방법과 마감수량산출에 연계하는 방법이 있습니다.</p>
          <p class="mt-0">필요에 따라 연계 기능을 사용하여 공사비용을 계산할 수 있습니다.</p>
        </div>
  
        
        <!-- 산출 기능 소개 -->
        <div class="px-3">
          <div class="row row-cols-xl-4 px-4 mt-4 menu-3-form">
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="columns-gap" class="intro-icon" />
              <h4 class="mt-2 mb-3">집계조회</h4>
              <p style="font-size: 13pt; color: dimgray;">WBS 데이터를 산출, 집계하여 상세하게 조회할 수 있습니다. 부재별, 층별 분석 데이터를 지원합니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="bezier2" class="intro-icon" />
              <h4 class="mt-2 mb-3">내역연계</h4>
              <p style="font-size: 13pt; color: dimgray;">산출, 집계한 WBS데이터를 Xcost 내역서 데이터로 연계할 수 있습니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <img src="../../assets/svg/btn-cad.svg" alt="..." class="intro-icon" >
              <h4 class="mt-2 mb-3">CAD연계</h4>
              <p style="font-size: 13pt; color: dimgray;">CAD 도면에서 평면 데이터를 읽어옵니다. 자재들을 연계 모델링하여 중복 데이터는 공제합니다.</p>
            </div>
          </div>
  
          <div class="row row-cols-xl-4 px-4 mt-3 menu-3-form">
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <!-- <img src="../../assets/svg/btn-cal.svg" alt="..." class="intro-icon" > -->
              <h4 class="mt-2 mb-3">기초산출</h4>
              <p style="font-size: 13pt; color: dimgray;">독립기초, 줄기초, 매트기초 등 각 배근에 따른 수량산출을 지원합니다. 콘크리트, 버림 등 설정이 가능합니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <!-- <b-icon icon="binoculars-fill" class="intro-icon" /> -->
              <h4 class="mt-2 mb-3">기둥산출</h4>
              <p style="font-size: 13pt; color: dimgray;">기본형, 원형, 곡선 등 각 배근에 따른 수량산출을 지원합니다. 기초, 벽, 기둥 연결이 반영됩니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <!-- <b-icon icon="calculator-fill" class="intro-icon" /> -->
              <h4 class="mt-2 mb-3">보산출</h4>
              <p style="font-size: 13pt; color: dimgray;">전단형, 양단중앙형 등 각 배근에 따른 수량산출을 지원합니다. 기초, 보, 기둥 연결이 반영됩니다.</p>
            </div>
          </div>
    
          <div class="row row-cols-xl-4 px-4 mt-3 menu-3-form">
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <!-- <b-icon icon="collection-fill" class="intro-icon" /> -->
              <h4 class="mt-2 mb-3">슬라브산출</h4>
              <p style="font-size: 13pt; color: dimgray;">복배근, 단배근 등 배근에 따른 수량산출 지원합니다. 네 변의 보와 거푸집, 개구부 정보가 반영됩니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <!-- <b-icon icon="binoculars-fill" class="intro-icon" /> -->
              <h4 class="mt-2 mb-3">벽산출</h4>
              <p style="font-size: 13pt; color: dimgray;">복배근 등 각 배근에 따른 수량산출 지원합니다. 인접 보와 기둥, 기초 연결, 개구부 정보가 반영됩니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <!-- <b-icon icon="calculator-fill" class="intro-icon" /> -->
              <h4 class="mt-2 mb-3">계단산출/기타산출</h4>
              <p style="font-size: 13pt; color: dimgray;">일반형 계단, 난간, 수벽 등 각 배근에 따른 수량산출을 지원합니다.</p>
            </div>
          </div>
        </div>
  
        <div class="pl-5 pr-5 mt-5 " style="font-size: large; color: dimgray;">
          <img class="w-gif-12" style="border: #c2c2c2 solid 1px;" src="../../../public/images/finish_3D_cal.gif" alt="3D Modeling gif">
          <h4 class="mt-3 mb-2">도면 3D 구조산출 작업</h4>
          <p class="mb-0">AutoCAD를 이용한 Xcost의 자체 Plugin System으로 도면을 자동 읽고난 뒤 산출하고, 3D모델링 작업까지 보여줍니다.</p>
          <p class="mb-3">클릭 몇번만으로 CAD도면의 수량산출과 3D모델링까지 자동으로 너무 쉽게 가능합니다.</p>
        </div>
  
        <div class="pl-5 pr-5 mt-5 " style="font-size: large; color: dimgray;">
          <h4 class="mt-3 mb-2">정보화 도면을 통한 3D 모델링 및 수량산출의 효율성 극대화</h4>
          <p class="mb-0">정보화 도면이란? CAD 도면을 XPixels-구조에서 분석할 수 있도록 전처리 과정을 거친 것을 의미합니다.</p>
  
          <p class="mt-3 mb-0">정보화 도면으로부터 가져오면,</p>
          <li>기초/보/기둥/슬라브/벽체 등의 입력 내용이 자동으로 채워지게 됩니다.</li>
          <li>3D 모델링이 가능하게 되어, 구조 수량 산출의 입력 결과를 시각적으로 검토할 수 있습니다.</li>
          <li style="list-style: none; color: #969696;">
            <b-badge variant="warning" pill>예시</b-badge>
            창호가 벽체보다 크게 표현되어 직관적으로 잘못 입력되었음을 파악할 수 있습니다.
          </li>
          <p class="mt-3 mb-0">XPixels-구조에서 제공하는 3D 모델링은 수량산출 결과에 대한 신뢰성을 확보해줄 수 있는 확실한 솔루션입니다.</p>
          <p>또한, 정보화 도면의 개념을 도입함으로써 수량산출 업무의 속도를 상당히 개선하였습니다.</p>
        </div>
      </section>
  
      <!-- 마감 소개 -->
      <section id="finish" class="py-5 fc-b section-pt-5" style="border-top: #c2c2c2 solid 1px; background-color: #fff; text-align: -webkit-left;">
        <div class="pl-5">
          <h1 class="mb-0 nanum-square-bold">편리한 마감 솔루션</h1>
        </div>
        <div class="pl-5 pr-5 mt-4 " style="font-size: large; color: dimgray;">
          <p class="mb-0 fc-b">공사 특성에 따른 산출식을 생성하고 관리하기란 굉장히 어렵습니다.</p>
          <p>이러한 문제를 해결하기 위해 '창호/내·외부/계단/철골/공용/토공' 등 내용에 따라 자동으로 산출식을 생성하고 수량을 계산합니다.</p>
  
          <p class="mb-1 mb-2 fc-b">물량 산출 업무의 효율성을 높여주는 다양한 기능이 있습니다.</p>
          <p class="mt-2">아파트 등 대단지에 대하여 패턴화된 산출 기법을 제공함으로써 산출을 간단하고 빠르게 완료할 수 있게 합니다.</p>
        </div>
  
        <div class="px-3">
          <div class="row row-cols-xl-4 px-4 mt-4 menu-3-form">
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="collection-fill" class="intro-icon" />
              <h4 class="mt-2 mb-3">복합자원</h4>
              <p style="font-size: 13pt; color: dimgray;">일련의 공사에 소요되는 자원을 꾸러미로 묶은 후, 한번에 산출서에 적용할 수 있게 하여 산출의 속도를 높여줍니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="binoculars-fill" class="intro-icon" />
              <h4 class="mt-2 mb-3">집계조회</h4>
              <p style="font-size: 13pt; color: dimgray;">산출 결과를 사용자의 설정에 따라 다양한 관점에서 집계 결과를 조회하고 검토할 수 있습니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="calculator-fill" class="intro-icon" />
              <h4 class="mt-2 mb-3">내역연계</h4>
              <p style="font-size: 13pt; color: dimgray;">내역서에 자동으로 연계하여 산출 결과에 따른 공사비를 손쉽게 계산할 수 있습니다.</p>
            </div>
          </div>
          <div class="row row-cols-xl-4 px-4 mt-4 menu-3-form">
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="file-earmark-text-fill" class="intro-icon" />
              <h4 class="mt-2 mb-3">산출서 읽어오기</h4>
              <p style="font-size: 13pt; color: dimgray;">엑셀로 출력한 산출서를 다시 XPixels로 읽어온 후 검토 및 작업이 가능합니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="gear-wide-connected" class="intro-icon" />
              <h4 class="mt-2 mb-3">산출설정</h4>
              <p style="font-size: 13pt; color: dimgray;">산출식에 대하여 사용자가 편집하여 더욱 효율적인 산출식 자동 생성에 활용할 수 있습니다.</p>
            </div>
            <div class="mx-3 mt-2 p-3 intro-3-menu">
              <b-icon icon="badge3d-fill" class="intro-icon" />
              <h4 class="mt-2 mb-3">3D 모델링</h4>
              <p style="font-size: 13pt; color: dimgray;">산출 부위에 대하여 3D 모델링을 통해 눈으로 확인하면서 물량산출을 할 수 있기 때문에 입력이 잘못된 내용을 바로잡기가 쉽습니다.</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- 맨하단 -->
      <section id="bottom" class="mt-0 py-5 fc-b" style="margin-top: 5rem; background-color: #f4f4f4; text-align: -webkit-left;">
        <div class="pl-5" style="color: black;">
          <h1 class="mb-2 nanum-square-bold">바로 시작하기</h1>
        </div>
        <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-left ml-auto mr-auto pl-4 pr-4">
          <div class="card h-100 m-add-size" style="min-width: 22rem; min-height: 340px !important; margin: 1rem 1rem;">
            <div class="card-body p-4">
              <div class="text-center">
                <img src="../../../public/images/xcost-free.png" alt="xcost-free" style="width: 100%;">
              </div>
              <div class="mt-4 text-left">
                <h4 style="color: black;">무료 기본기능</h4>
              </div>
  
              <div class="mt-2 text-left" style="font-size: 12pt;">
                <p style="color: dimgray;">Xcost 적산 무료버전에서 스마트한 적산 솔루션을 사용해보세요. 여러개의 프로젝트를 편리하게 관리할 수 있습니다.</p>
              </div>
  
              <div class="card-footer border-top-0 bg-transparent mt-4">
                <div class="text-center">
                  <b-button class="btn btn-outline-howcost mt-auto py-2 pl-5 pr-5" style="border-radius: 30px; font-size: large;" :href="$xcostDownload">다운로드</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="mt-4 pl-5" style="color: black;">
          <h4>더 많은 기능을 사용하고 싶으신가요?</h4>
          <b-button class="store-move-btn mt-2 pl-4 pr-4 p-2 fc-b" style="border-color: black;" href="#/store">나에게 맞는 적산 솔루션 구입</b-button>
        </div>
      </section>
  
    </div>

    <!-- Section burger -->
    <div class="section-burger" :class="{ 'show': sectionBurgerButton }" @click="menuNavToggle">
      <img src="../../assets/svg/icon-menu-burger.svg" alt="controller menu">
    </div>
    <!-- Menu navigation -->
    <div class="menu-nav" :class="{ 'show': isMenuNav }">
      <div style="display: flex; justify-content: space-between; padding: 6px 12px 8px;">
        <img class="mr-1" src="../../assets/svg/icon-world.svg" alt="internet">
        <img class="ml-1" style="cursor: pointer;" src="../../assets/svg/icon-arrow-right.svg" alt="EXIT" @click="menuNavToggle">
      </div>
      <div style="display: flex; flex-direction: column; padding: 2px 20px 12px;">
        <a class="tp-section" @click="scrollToSection('top')">맨 위로</a>
        <a class="tp-section" @click="scrollToSection('start')">바로 시작하기</a>
        <a class="tp-section" @click="scrollToSection('details')">내역 솔루션</a>
        <a class="tp-section" @click="scrollToSection('structure')">구조 솔루션</a>
        <a class="tp-section" @click="scrollToSection('finish')">마감 솔루션</a>
        <a class="tp-section" @click="scrollToSection('bottom')">맨 아래로</a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "Introduction",
  data() {
    return {
      VoltCanversOpt: {
        fullScreen: {
          enable: false
        },
        fpsLimit: 120,
        particles: {
          number: {
            value: 100
          },
          color: {
            value: '#aaaaaa'
          },
          move: {
            enable: true
          },
          links: {
            enable: true,
            distance: 100,
            color: '#aaaaaa'
          }
        },
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: 'grab'
            },
            onClick: {
              enable: true,
              mode: 'repulse'
            },
          },
          modes: {
            grab: {
              distance: 200,
              links: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 0.8
            },
            repulse: {
              distance: 200
            },
            push: {
              quantity: 4
            },
            remove: {
              quantity: 2
            }
          }
        }
      },
      sectionBurgerButton: false,
      isMenuNav: true,
    };
  },
  created() {

  },
  mounted() {
    // 스크롤 이벤트 리스너 등록
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 스크롤 이벤트 핸들러
    handleScroll(e) {
      // console.log(" window.scrollY -------> ", window.scrollY);
      this.sectionBurgerButton = (window.scrollY > 650); // 페이지가 일정 이상 스크롤되면 버튼을 표시
    },
    menuNavToggle() {
      this.isMenuNav = !this.isMenuNav;
    },
    // section으로 scroll
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  beforeDestroy() {
    // 컴포넌트가 파괴되기 전에 이벤트 리스너 해제
    window.removeEventListener("scroll", this.handleScroll);
  }
};

</script>

<style>

.section-burger {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex; /* Flex 정렬을 위한 설정 */
  align-items: center; /* 수직 가운데 정렬 */
  justify-content: center; /* 수평 가운데 정렬 */
  padding: 10px 10px;
  color: white;
  background: #f8f8f8;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity 0.5s;
  opacity: 0;
}

.section-burger.show {
  transition: opacity 0.5s;
  opacity: 1;
}

.section-burger:hover {
  opacity: 0.6;
}

.menu-nav {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex; /* Flex 정렬을 위한 설정 */
  align-items: center; /* 수직 가운데 정렬 */
  justify-content: center; /* 수평 가운데 정렬 */
  /* padding: 16px 20px; */
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* width: 200px; */
  /* display: none; */
  z-index: -1;
  transition: opacity 0.3s;
  opacity: 0;
}

.menu-nav.show {
  display: block;
  z-index: 10;
  transition: opacity 0.3s;
  opacity: 1;
}

.section-pt-5 {
  padding-top: 5rem !important;
}

.tp-section {
  padding: 1px 0;
  cursor: pointer;
}

.tp-section:hover {
  color: #39a6cf !important;
}

.menu-nav-close {
  transition: opacity 0.3s;
  opacity: 0;
}

</style>
